import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {QualitySection,QualitySectionHeadingWrapper,QualityCustomContainer,
    QualityColBig,QualityColSmall,QualityColBigFigure,QualityColSmallFigure} from './quality.style';
import {SectionHeading} from '../Common/common.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";

const Quality = () => {
    const JSONData  = useStaticQuery(graphql`{
  interiorJson {
    Quality {
      DelayConstant
      SectionHeading
      QualityColBig {
        QualityColBigImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        QualityColBigHeading
        QualityColPara
      }
      QualityColSmall1 {
        QualityColSmallImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        QualityColSmallHeading
        QualityColPara
      }
      QualityColSmall2 {
        QualityColSmallImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        QualityColSmallHeading
        QualityColPara
      }
    }
  }
}
`);

    return (
        <QualitySection>
            <Container>
                <Row>
                    <Col md="12">
                        <QualitySectionHeadingWrapper>
                            <Fade bottom delay={1*JSONData.interiorJson.Quality.DelayConstant}>
                                <SectionHeading>
                                    {JSONData.interiorJson.Quality.SectionHeading}
                                </SectionHeading>
                            </Fade>
                        </QualitySectionHeadingWrapper>
                        <Fade bottom delay={1*JSONData.interiorJson.Quality.DelayConstant}>
                            <QualityCustomContainer>
                                <QualityColBig>
                                <QualityColBigFigure>
                                    <GatsbyImage
                                        image={JSONData.interiorJson.Quality.QualityColBig.QualityColBigImg.childImageSharp.gatsbyImageData}
                                        className="QualityColBigImg"
                                        alt="" />
                                    </QualityColBigFigure>
                                    <h3>
                                    {JSONData.interiorJson.Quality.QualityColBig.QualityColBigHeading}
                                    </h3>
                                    <p>
                                    {JSONData.interiorJson.Quality.QualityColBig.QualityColPara}
                                    </p>
                                </QualityColBig>

                                <QualityColSmall marginProp="0px 40px">
                                    <QualityColSmallFigure >
                                        <GatsbyImage
                                            image={JSONData.interiorJson.Quality.QualityColSmall1.QualityColSmallImg.childImageSharp.gatsbyImageData}
                                            className="QualityColSmallImg"
                                            alt="" />
                                    </QualityColSmallFigure>
                                    <h6>
                                        {JSONData.interiorJson.Quality.QualityColSmall1.QualityColSmallHeading}
                                    </h6>
                                    <p>
                                    {JSONData.interiorJson.Quality.QualityColSmall1.QualityColPara}
                                    </p>
                                </QualityColSmall>

                                <QualityColSmall>
                                    <QualityColSmallFigure>
                                        <GatsbyImage
                                            image={JSONData.interiorJson.Quality.QualityColSmall2.QualityColSmallImg.childImageSharp.gatsbyImageData}
                                            className="QualityColSmallImg"
                                            alt="" />
                                    </QualityColSmallFigure>
                                    <h6>
                                        {JSONData.interiorJson.Quality.QualityColSmall2.QualityColSmallHeading}
                                    </h6>
                                    <p>
                                    {JSONData.interiorJson.Quality.QualityColSmall2.QualityColPara}
                                    </p>
                                </QualityColSmall>
                            </QualityCustomContainer>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </QualitySection>
    );
}

export default Quality;
