import React,{Component} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {TestimonialsSection,TestimonialsContainer,CardOuterWrapper,Card,CardProfileLayout,
    ProfilePicLayout,ProfileNameLayout,PrevImg,NextImg,NextPrev,ImgButton} from './testimonials.style';
import Slider from "react-slick";
import {SectionHeading} from "../Common/common.style";
import { StaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Testimonials extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                }
            ]
        };

        return (
            <TestimonialsSection id="testimonialsContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*this.props.interiorJson.Testimonials.DelayConstant}>
                                <SectionHeading>{this.props.interiorJson.Testimonials.TestimonialHeading}</SectionHeading>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
                <Fade bottom delay={1*this.props.interiorJson.Testimonials.DelayConstant}>
                    <TestimonialsContainer>
                        <Slider ref={c => (this.slider = c)} {...settings}>
                        {
                            this.props.interiorJson.Testimonials.CardProfileLayout.map((item,idx) => {
                                return (
                                    <CardOuterWrapper>
                                        <Card>
                                            <h6>
                                                {item.TestimonailHeading}
                                            </h6>
                                            <p>
                                                {item.Testimonial}
                                            </p>

                                            <CardProfileLayout>
                                                <ProfilePicLayout>
                                                    <GatsbyImage
                                                        image={item.ProfilePic.childImageSharp.gatsbyImageData}
                                                        className="ProfilePic"
                                                        alt="" />
                                                </ProfilePicLayout>
                                                <ProfileNameLayout>
                                                    <h6>
                                                        {item.ProfileName}
                                                    </h6>
                                                    <p>
                                                        {item.Designation}
                                                    </p>
                                                </ProfileNameLayout>
                                            </CardProfileLayout>
                                        </Card>
                                    </CardOuterWrapper>
                                );
                            })
                        }
                        </Slider>
                    </TestimonialsContainer>
                </Fade>
                <NextPrev>
                    <ImgButton onClick={this.previous} aria-label="Prev Button">
                        <PrevImg src={this.props.interiorJson.Testimonials.PrevImg} alt=""/>
                    </ImgButton>
                    <ImgButton onClick={this.next} aria-label="Next Button">
                        <NextImg src={this.props.interiorJson.Testimonials.NextImg} alt=""/>
                    </ImgButton>
                </NextPrev>
            </TestimonialsSection>
        );
    }
}


const TestimonialsPageSection = () => (
    <StaticQuery
        query={graphql`{
  interiorJson {
    Testimonials {
      TestimonialHeading
      CardProfileLayout {
        ProfilePic {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        ProfileName
        Designation
        TestimonailHeading
        Testimonial
      }
      PrevImg
      NextImg
    }
  }
}
`}
        render={(data) => (
            <Testimonials interiorJson={data.interiorJson}/>
        )}
    />
  )
  export default TestimonialsPageSection;
