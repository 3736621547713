import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,FooterCol,FooterForm,FooterInput,FooterFormSubmit,
    FooterLi,FooterSocial,BottomFooterPara,BottomLink,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon} from './footer.style';

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            interiorJson {
                Footer {
                    DelayConstant
                    FooterCol1{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol2{
                        FooterSocial{
                            FooterSocialImg
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        FooterUl{
                            FooterMenu
                            Href
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);

    return (
        <div>
            <FooterSection>
                <Container>
                    <Fade Bottom delay={JSONData.interiorJson.Footer.DelayConstant}>
                        <Row>
                            <Col md="3">
                                <FooterCol>
                                <h4>{JSONData.interiorJson.Footer.FooterCol1.FooterTitle}</h4>
                                {
                                    JSONData.interiorJson.Footer.FooterCol1.FooterPara.map((item,idx) => {
                                    return <p>
                                        {item.Para}
                                        </p>
                                    })
                                }
                                </FooterCol>
                            </Col>
                            <Col md="3">
                                <FooterCol>
                                <h4>{JSONData.interiorJson.Footer.FooterCol3.FooterTitle}</h4>
                                {
                                    JSONData.interiorJson.Footer.FooterCol3.FooterPara.map((item,idx) => {
                                    return <p>
                                        {item.Para}
                                        </p>
                                    })
                                }
                                </FooterCol>
                            </Col>
                            <Col md="3">
                                <FooterCol>
                                    <h4>{JSONData.interiorJson.Footer.FooterCol4.FooterTitle}</h4>
                                    <ScrollSpy offset={-59} items={JSONData.interiorJson.Footer.Items} currentClassName="is-current">
                                    {
                                        JSONData.interiorJson.Footer.FooterCol4.FooterUl.map((item,idx) => {
                                        return <FooterLi>
                                                <AnchorLink offset={55} href={item.Href}>
                                                    {item.FooterMenu}
                                                </AnchorLink>
                                            </FooterLi>
                                        })
                                    }
                                    </ScrollSpy>
                                </FooterCol>
                            </Col>
                            <Col md="3">
                                <FooterCol>
                                    <h4>Free Consultation</h4>
                                    <FooterForm>
                                        <FooterInput aria-label="Email" type="email" placeholder="Email ID"/>
                                        <FooterFormSubmit>Submit</FooterFormSubmit>
                                    </FooterForm>
                                </FooterCol>
                                <FooterCol>
                                    <FooterSocial href="#" aria-label="Facebook Link">
                                        <FbIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="#" aria-label="Twitter Link">
                                        <TwitterIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="#" aria-label="LinkedIn Link">
                                        <LinkedinIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="#" aria-label="Instagram Link">
                                        <InstaIcon/>
                                    </FooterSocial>
                                </FooterCol>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <BottomFooterPara>
                                    {JSONData.interiorJson.Footer.BottomFooterPara}
                                    <BottomLink rel="noreferrer" href={JSONData.interiorJson.Footer.BottomFooterLink} target="_blank">
                                    {JSONData.interiorJson.Footer.BottomFooterName}
                                    </BottomLink>
                                </BottomFooterPara>
                            </Col>
                        </Row>
                    </Fade>
                </Container>
            </FooterSection>
        </div>
    )
};

export default Footer;
