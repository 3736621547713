import {createGlobalStyle} from 'styled-components';
import {device} from "./device"
const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Noto Sans', sans-serif;
        font-weight:500;
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Muli', sans-serif;
        font-weight: 700;
        color:#2c2c2c;
        margin-bottom:20px;
    }
    h1{
        font-size:50px;
        line-height:56px;

        @media ${device.tablet} {
            width:100%;
            text-align:center;
            font-size:45px;
        }
    }
    h2{
        font-size:36px;
        line-height:42px;
    }
    h3{
        font-size:32px;
        line-height:38px;
    }
    h4{
        font-size:24px;
        line-height:30px;
    }
    h5{
        font-size:22px;
        line-height:26px;
    }
    h6{
        font-size:20px;
        line-height:24px;
    }
    p{
        color:#7a7f83;
        margin-bottom:26px;
        font-size:14px;
    }
    img{
        margin-bottom:0px;
    }
    a{
        text-decoration:none;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
