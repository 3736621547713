import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FeaturesSection,FeatureCustomContainer,
    FeatureCol} from './featuers.style'
import {SectionHeading} from '../Common/common.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`{
  interiorJson {
    KeyFeatures {
      DelayConstant
      SectionHeading
      FeatureCustomContainer {
        FeatureImg {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        FeatureText
      }
    }
  }
}
`);
    return (
        <FeaturesSection id="featuresContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.interiorJson.KeyFeatures.DelayConstant}>
                            <SectionHeading>{JSONData.interiorJson.KeyFeatures.SectionHeading}</SectionHeading>
                        </Fade>
                    </Col>
                </Row>
                <FeatureCustomContainer>
                <Row>
                {
                    JSONData.interiorJson.KeyFeatures.FeatureCustomContainer.map((item,idx) => {
                    return (
                        <Col md="3" sm="6" xs="6">
                            <Fade bottom delay={(idx+1)*JSONData.interiorJson.KeyFeatures.DelayConstant}>
                                <FeatureCol>
                                        {
                                            (idx % 2 === 1) &&
                                            <GatsbyImage
                                                image={item.FeatureImg.childImageSharp.gatsbyImageData}
                                                className="FeatureImg"
                                                alt="" />
                                        }

                                        {
                                            (idx % 2 === 0) &&
                                            <GatsbyImage
                                                image={item.FeatureImg.childImageSharp.gatsbyImageData}
                                                className="FeatureImgTopPadded"
                                                alt="" />
                                        }
                                        <p>{item.FeatureText}</p>
                                </FeatureCol>
                            </Fade>
                        </Col>
                    );
                    })
                }
                </Row>
                </FeatureCustomContainer>
            </Container>
        </FeaturesSection>
    );
}

export default Features
