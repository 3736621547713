import React from "react";
import Banner from "../containers/Interior/Banner";
import HeaderMenuBS from "../containers/Interior/HeaderMenuBS";
import Footer from "../containers/Interior/Footer";
import Testimonials from "../containers/Interior/Testimonials";
import KeyServices from "../containers/Interior/KeyServices";
import Features from "../containers/Interior/Features";
import Quality from "../containers/Interior/Quality";
import Gallery from "../containers/Interior/Gallery";
import Contact from "../containers/Interior/Contact";
import GlobalStyle from "../containers/Interior/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Interior/Common/fonts"
import BuyNow from "../components/BuyNow";

const IndexPage = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenuBS/>
       <main>
            <Seo 
                title="Interior ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy Interior Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <Quality/>
            <Features/>
            <KeyServices/>
            <Gallery/>
            <Testimonials/>
            <Contact/>
        </main>
       <Footer/>
   </div>

)

export default IndexPage;
