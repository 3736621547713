import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import {SectionHeading} from '../Common/common.style';
import {GallerySection,GalleryImageWrapper} from './gallery.style';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import { GatsbyImage } from "gatsby-plugin-image";

const Gallery = () => {
    const JSONData  = useStaticQuery(graphql`{
  interiorJson {
    Gallery {
      DelayConstant
      SectionHeading
      GalleryImageWrapper {
        GalleryImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`);
    return (
        <GallerySection id="galleryContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <Fade bottom delay={1*JSONData.interiorJson.Gallery.DelayConstant}>
                            <SectionHeading>
                                {JSONData.interiorJson.Gallery.SectionHeading}
                            </SectionHeading>
                        </Fade>
                    </Col>
                </Row>
            </Container>
            <Fade bottom delay={1*JSONData.interiorJson.Gallery.DelayConstant}>
            <GalleryImageWrapper>
            {
                JSONData.interiorJson.Gallery.GalleryImageWrapper.map((item,idx) => {
                    return (
                        <GatsbyImage
                            image={item.GalleryImage.childImageSharp.gatsbyImageData}
                            className="GalleryImage"
                            imgStyle={{
                                marginBottom:"0px",
                            }}
                            alt="" />
                    );
                })
            }
            </GalleryImageWrapper>
            </Fade>
        </GallerySection>
    );
}

export default Gallery;